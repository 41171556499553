import Image from "next/image";
import twitter_icon from "../public/assets/images/icon/twitter.png";
import facebook_icon from "../public/assets/images/icon/facebook.png";
import mail_icon from "../public/assets/images/icon/mail.png";
import inst_icon from "../public/assets/images/icon/inst.png";

// optional: prop.flow: horizontal | vertical
export default function social({ flow }) {
  flow = flow ?? "";
  
  return (
    <div className={"social_icons_outer " + flow ?? ""}>
      <a target="_blank" rel="noopener noreferrer" href={process.env.socialTwitter} className="social_icon_wrap twitter_icon_wrap">
        <Image
          src={twitter_icon}
          alt="twitter_icon"
          className="twitter_icon"
          priority="true"
          layout="responsive"
        />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={process.env.socialFacebook} className="social_icon_wrap facebook_icon_wrap">
        <Image
          src={facebook_icon}
          alt="facebook_icon"
          className="facebook_icon"
          priority="true"
          layout="responsive"
        />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={`mailto:${process.env.emailContact}`} className="social_icon_wrap mail_icon_wrap">
        <Image
          src={mail_icon}
          alt="mail_icon"
          className="mail_icon"
          priority="true"
          layout="responsive"
        />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={process.env.socialInsta} className="social_icon_wrap inst_icon_wrap">
        <Image
          src={inst_icon}
          alt="inst_icon"
          className="inst_icon"
          priority="true"
          layout="responsive"
        />
      </a>
    </div>
  );
}
