import Header from "../components/Header";
import Social from "../components/social";
import CarouselPlates from "../components/Frontpage/carouselPlates";
import CarouselLocations from "../components/Frontpage/carouselLocations";
import CarouselComingsoon from "../components/Frontpage/carouselComingsoon";
import Image from "next/image";
import section_1_right_image from "../public/assets/images/section_1_right_2x.png";
import section_2_left_image from "../public/assets/images/section_2_left_2x.png";
import section_1_mobile_appstore from "../public/assets/images/bttn-apple-mobile.png";
import section_1_mobile_playstore from "../public/assets/images/bttn-google-mobile.png";
import appstore_white from "../public/assets/images/bttn-apple-white.png";
import playstore_white from "../public/assets/images/bttn-google-white.png";

export default function Frontpage() {
  return (
    <>
      <section className="section section_1">
        <div className="container">
          <Header />
          <div className="section_1_wrap">
            <div className="section_1_left">
              <div className="section_1_left_one heading section_heading">
                Discovering <br />
                the most <span className="color-pink">creative</span>{" "}
                <span className="color-green">vegan</span> food around the
                world.
              </div>
              <div className="section_1_left_two section_heading_underline"></div>
              <div className="section_1_left_three">
                {`We've redefined how people search for food by creating an
                interface where you can easily view the actual plate from a
                restaurant and see individual plate ratings.`}
              </div>
              <div className="section_1_four desktop-display-none mobile-display-flex">
                <a target="_blank" rel="noopener noreferrer" href={process.env.appstorelink} className="section_1_mobile_appstore_wrap">
                  <Image
                    src={section_1_mobile_appstore}
                    alt="section_1_mobile_appstore"
                    className="section_1_mobile_appstore"
                    priority="true"
                    layout="responsive"
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer" href={process.env.playstorelink} className="section_1_mobile_playstore_wrap">
                  <Image
                    src={section_1_mobile_playstore}
                    alt="section_1_mobile_playstore"
                    className="section_1_mobile_playstore"
                    priority="true"
                    layout="responsive"
                  />
                </a>
              </div>
            </div>
            <div className="section_1_right">
              <div className="section_1_right_image_wrap">
                <Image
                  src={section_1_right_image}
                  alt="section_1_right_image"
                  className="section_1_right_image"
                  priority="true"
                  layout="responsive"
                />
              </div>
              <div className="section_1_social_outer">
                <Social flow="vertical" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_2">
        <div className="container">
          <div className="section_2_wrap">
            <div className="section_2_left">
              <div className="section_2_left_image_wrap">
                <Image
                  src={section_2_left_image}
                  alt="section_2_left_image"
                  className="section_2_left_image"
                  priority="true"
                  layout="responsive"
                />
              </div>
            </div>
            <div className="section_2_right">
              <div className="section_2_right_one heading section_heading">
                Connect with your <span className="color-green">vegan</span>{" "}
                <span className="color-pink">soulmates.</span>
              </div>
              <div className="section_2_right_two section_heading_underline"></div>
              <div className="section_2_right_three">
                {`And if you want to find someone to share a plate with, we've
                invented a Food for 2™ feature that breaks the ice for you.
                Make new friends or meet a potential life partner who shares
                similar interests in a healthier lifestyle.`}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_3">
        <div className="container">
          <div className="section_3_one heading section_heading section_3_heading">
            Food for 2™
          </div>
          <div className="section_heading_underline section_3_heading_underline"></div>
          <div className="section_3_three">
            Facilitating social meet ups with people who share similar interests
            in a healthier lifestyle.
          </div>
        </div>
      </section>
      <section className="section section_4">
        <div className="container-full section_4_container-full">
          <div className="section_4_wrap">
            <div className="section_4_left">
              <div className="heading section_heading section_4_one">
                <span className="color-pink">Supporting</span> all restaurants
                that serve <span className="color-green">vegan</span> plates.
              </div>
              <div className="section_heading_underline"></div>
              <div className="section_4_three">
                <div>
                  Plant-based creativity is taking gastronomy to new heights and
                  we are curating new vegan experiences from the most beautiful
                  burgers to the most elegant fine-dining menus. If your
                  restaurant offers vegan options and you would like to be
                  featured on LovePlates please submit your plates through
                  the link below; there is no cost to join.
                </div>
                <div>
                  <a
                    href={`mailto:${process.env.emailContact}?subject=${process.env.emailContactSubject}`}
                    className="display-inline-block button button-pink section_4_left_button"
                  >
                    Join LovePlates™
                  </a>
                </div>
              </div>
            </div>
            <div className="section_4_right">
              <CarouselPlates />
            </div>
          </div>
        </div>
      </section>
      <section className="section section_5">
        <div className="container-full section_5_container-full">
          <div className="container heading section_heading section_5_one">
            <span className="color-green">Vegan</span> Plates{" "}
            <span className="color-pink">Worldwide.</span>
          </div>
          <div className="section_heading_underline section_5_heading_underline"></div>
          <div className="container section_5_three">
            We are currently live in the following cities.
          </div>
          <div className="section_5_four">
            <CarouselLocations />
          </div>
          <div className="container font-secondary section_5_five">
            More cities coming soon.
          </div>
        </div>
      </section>
      <div className="section section_5_six">
            <CarouselComingsoon />
      </div>
      <section className="section section_6">
        <div className="container">
          <div className="section_heading_underline section_6_heading_underline"></div>
          <div className="section_6_one heading section_heading section_6_heading">
            {`“It's 2022, we should be able to see the food before we order
            it.”`}
          </div>
          <div className="section_heading_underline section_6_heading_underline"></div>
          <div className="section_6_three">
            <a target="_blank" rel="noopener noreferrer" href={process.env.appstorelink} className="section_6_appstore_white_wrap">
              <Image
                src={appstore_white}
                alt="appstore_white"
                className="appstore_white"
                priority="true"
                layout="responsive"
              />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={process.env.playstorelink} className="section_6_playstore_white_wrap">
              <Image
                src={playstore_white}
                alt="playstore_white"
                className="playstore_white"
                priority="true"
                layout="responsive"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
