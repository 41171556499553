import Image from "next/image";
import footer_logo_image from "../public/assets/images/logo_big_4x.png";
import Social from './social'
import Link from "next/link";

export default function Footer() {
  return (
    <>
      <section className="section footer">
        <div className="container">
          <div className="footer-wrap">
            <div className="footer-one">
              <div className="footer_logo_image_wrap">
                <Image
                  src={footer_logo_image}
                  alt="footer_logo_image"
                  className="footer_logo_image"
                  priority="true"
                  layout="responsive"
                />
              </div>
            </div>
            <div className="footer-two">
                <Link href="/privacy-policy"><a>Privacy Policy</a></Link>
                <span className="footer-links-separator"></span>
                <Link href="/terms-of-service"><a>Terms of Service</a></Link>
                <span className="footer-links-separator"></span>
                <Link href={`mailto:${process.env.emailContact}`} ><a>Contact us</a></Link>
            </div>
            <div className="footer-three">
                <Social flow="horizontal" />
            </div>
          </div>
          <div className="footer-copyright">
            © 2022 LovePlates LLC
          </div>
        </div>
      </section>
    </>
  );
}
