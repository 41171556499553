import Image from "next/image";
// import { Splide, SplideSlide } from "splide-nextjs/react-splide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import IconMapPin from "../../public/assets/images/icon/map-pin.png";
// import ImageLogo from "../../public/assets/images/logo_big_4x.png";
import ImageLosangeles from "../../public/assets/images/locations/losangeles.png";
import ImageDubai from "../../public/assets/images/locations/dubai.png";
import ImageSanfrancisco from "../../public/assets/images/locations/sanfrancisco.png";
import ImageLondon from "../../public/assets/images/locations/london.png";

export default function CarouselLocations() {

  return (
    <div className="slider_outer slider_carousel-locations">
      <Splide
        className="slider"
        options={{
          type: "loop",
          perPage: "1",
          perMove: 1,
          padding: "5rem",
          gap: "3.2rem",
          arrows: false,
          drag: true,
          pagination: true,
          mediaQuery: 'min',
          breakpoints: {
            769: {
              destroy: true,
            },
          },
        }}
      >
        <SplideSlide>
          <div className="carousel-locations-image-wrap" 
          style={{backgroundImage: `url(${ImageLosangeles.src})`}}
          >
            {/* <Image
              src={ImageLosangeles}
              alt="ImageLosangeles"
              priority="true"
              // width="1148rem"
              // height="582rem"
              layout="responsive"
              // placeholder="blur"
              // blurDataURL={ImageBlurData}
            /> */}
          </div>
          <div className="splide_caption">
            <div className="splide_caption_image"></div>
            <span className="splide_caption_text">Los Angeles</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-locations-image-wrap"
          style={{backgroundImage: `url(${ImageDubai.src})`}}
          >
          </div>
          <div className="splide_caption">
            <div className="splide_caption_image"></div>
            <span className="splide_caption_text">Dubai</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-locations-image-wrap"
          style={{backgroundImage: `url(${ImageSanfrancisco.src})`}}
          >
          </div>
          <div className="splide_caption">
            <div className="splide_caption_image"></div>
            <span className="splide_caption_text">San Francisco</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-locations-image-wrap"
          style={{backgroundImage: `url(${ImageLondon.src})`}}
          >
          </div>
          <div className="splide_caption">
            <div className="splide_caption_image"></div>
            <span className="splide_caption_text">London</span>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
}
