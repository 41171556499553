import Image from "next/image";
// import { Splide, SplideSlide } from "splide-nextjs/react-splide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import IconMapPin from "../../public/assets/images/icon/map-pin.png";
// import ImageLogo from "../../public/assets/images/logo_big_4x.png";
import ImageOne from "../../public/assets/images/plates/one.png";
import ImageTwo from "../../public/assets/images/plates/two.png";
import ImageThree from "../../public/assets/images/plates/three.png";
import ImageFour from "../../public/assets/images/plates/four.png";

export default function CarouselPlates() {

  return (
    <div className="slider_outer slider_carousel-plates">
      <Splide
        className="slider"
        options={{
          type: "loop",
          perPage: "1",
          perMove: 1,
          padding: "5rem",
          gap: "3.2rem",
          arrows: false,
          drag: true,
          pagination: true,
          mediaQuery: 'min',
          width: "100vw",
          breakpoints: {
            769: {
              destroy: true,
            },
          },
        }}
      >
        <SplideSlide>
          <div className="carousel-plates-image-wrap">
            <Image
              src={ImageOne}
              alt="ImageOne"
              priority="true"
              // width="1148rem"
              // height="582rem"
              layout="responsive"
              // placeholder="blur"
              // blurDataURL={ImageBlurData}
            />
          </div>
          <div className="splide_caption">
            <div className="splide_caption_title">Beet & Tofu Benedict</div>
            <span className="splide_caption_restaurant">Just Vegan</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-plates-image-wrap">
            <Image
              src={ImageTwo}
              alt="ImageTwo"
              // width="1148rem"
              // height="582rem"
              layout="responsive"
              priority="true"
              // placeholder="blur"
              // blurDataURL={ImageBlurData}
            />
          </div>
          <div className="splide_caption">
          <div className="splide_caption_title">Philly Cheesesteak</div>
            <span className="splide_caption_restaurant">{`Doomie’s Home Cookin’`}</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-plates-image-wrap">
            <Image
              src={ImageThree}
              alt="ImageThree"
              // width="1148rem"
              // height="582rem"
              layout="responsive"
              priority="true"
              // placeholder="blur"
              // blurDataURL={ImageBlurData}
            />
          </div>{" "}
          <div className="splide_caption">
          <div className="splide_caption_title">Courgetti Spaghetti</div>
            <span className="splide_caption_restaurant">Purezza</span>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="carousel-plates-image-wrap">
            <Image
              src={ImageFour}
              alt="ImageFour"
              // width="1148rem"
              // height="582rem"
              layout="responsive"
              priority="true"
              // placeholder="blur"
              // blurDataURL={ImageBlurData}
            />
          </div>
          <div className="splide_caption">
          <div className="splide_caption_title">Kalibos Cabbage</div>
            <span className="splide_caption_restaurant">Silo London</span>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
}
