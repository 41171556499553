import Image from "next/image";
import ImageLogo from "../public/assets/images/logo_big_4x.png";
import BttnApple from "../public/assets/images/bttn-apple_2x.png";
import BttnGoogle from "../public/assets/images/bttn-google_2x.png";
import IconBurger from "../public/assets/images/icon/burger.png";
import Link from "next/link";

export default function Header() {
  return (
    <>
      <div className="header">
        <div className="logo">
          <Link href="/">
            <a className="header-logo-ahref logo-a">
            <div className="logo-image-div">
              <Image
                src={ImageLogo}
                alt="logo"
                className="logo-image"
                priority="true"
                layout="responsive"
              />
            </div>
            <span className="logo-text">LovePlates™</span>
            </a>
          </Link>
        </div>
        <div className="tagline">
          LovePlates™ is a 100% vegan food discovery app.
        </div>
        <div className="header-right">
          <a target="_blank" rel="noopener noreferrer" href={process.env.appstorelink} className="header-right-bttn bttn-apple-div">
            <Image
              src={BttnApple}
              alt="logo"
              className="logo-image"
              priority="true"
              layout="responsive"
            />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={process.env.playstorelink} className="header-right-bttn bttn-google-div">
            <Image
              src={BttnGoogle}
              alt="logo"
              className="logo-image"
              priority="true"
              layout="responsive"
            />
          </a>
        </div>
        <div className="header-burger">
          <div className="icon-burger-div">
            <Image
              src={IconBurger}
              alt="IconBurger"
              className="icon-burger"
              priority="true"
              layout="responsive"
            />
          </div>
        </div>
      </div>
    </>
  );
}
