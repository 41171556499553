import React from 'react'
import Head from "next/head";
import Frontpage from "../components/frontpage";
import Footer from "../components/Footer";

export default function Home() {
  React.useEffect(() => {
    document.querySelector('html').style.fontSize = '.695vw';
    document.querySelector('html').style.padding = 'env(safe-area-inset)';
  }, [])

  return (
    <div id="homepage" className="main_outer">
      <Head>
        <title>LovePlates</title>
        <meta name="description" content="LovePlates homepage" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
      </Head>
      <Frontpage />
      <Footer />
    </div>
  );
}
