import Image from "next/image";
// import { Splide, SplideSlide } from "splide-nextjs/react-splide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import IconMapPin from "../../public/assets/images/icon/map-pin.png";

export default function CarouselComingsoon() {
  const list_comingsoon = [
    "New York City",
    "Boston",
    "Portland",
    "Seattle",
    "Taipei",
    "Hong Kong",
    "Bangkok",
    "Berlin",
    "Paris",
    "Rome",
  ];

  const list_comingsoon_jsx = () => {
    return list_comingsoon.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <SplideSlide>
            <div className="splide_caption">
              <div className="splide_caption_image"></div>
              <span className="splide_caption_text">{item}</span>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="splide_caption_dot_wrap">
              <span className="splide_caption_dot"></span>
            </div>
          </SplideSlide>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="slider_outer slider_carousel-comingsoon">
        <div className="overlay-1-left"></div>
        <div className="overlay-1-right"></div>
      <Splide
        className="slider"
        options={{
          type: "loop",
          autoWidth: true,
          perMove: 1,
          gap: "3.2rem",
          arrows: false,
          pagination: false,
          drag: "free",
          // breakpoint: {
          //   1439: {
          //     perPage: 3,
          //   },
          //   768: {
          //     perPage: 2,
          //   },
          // },
        }}
      >
        {list_comingsoon_jsx()}
      </Splide>
    </div>
  );
}
